import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HttpClientXml from "../../utils/HttpClientXml";
import toast from "react-hot-toast";

const HomeCard = () => {
  const [Contractor, setContractor] = useState({ length: "", name: "" });
  const [Homeowner, setHomeowner] = useState({ length: "", name: "" });
  const [SubContractor, setSubContractor] = useState({ length: "", name: "" });
  const [Financer, setFinancer] = useState({ length: "", name: "" });

  console.log(Financer, "Contractor");
  // const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const item = [
    "rgb(236, 64, 122)",
    "rgb(73, 163, 241)",
    "rgb(102, 187, 106)",
    "rgb(187, 23, 245)",
  ];

  const getCategoryData = async () => {
    // setIsLoading(true);
    const res = await HttpClientXml.requestData(
      "get-all-user-acording-to-role",
      "GET",
      {}
    );
    console.log("resGetCat", res);
    if (res && res?.status) {
      setContractor({
        length: res?.data?.[0]?.userList?.length,
        name: res?.data?.[0]?.name,
      });
      setHomeowner({
        length: res?.data?.[1]?.userList?.length,
        name: res?.data?.[1]?.name,
      });
      setSubContractor({
        length: res?.data?.[2]?.userList?.length,
        name: res?.data?.[2]?.name,
      });
      setFinancer({
        length: res?.data?.[3]?.userList?.length,
        name: res?.data?.[3]?.name,
      });
      // toast.success(res?.msg);
    } else {
      toast("Cant fetch details");
    }
  };
  useEffect(() => {
    getCategoryData();
  }, []);
  return (
    <>
      <div className="HomeTop">
        <div className="row">
          {/* ....Contractor.... */}
          <div className="col-md-4 col-12">
            <div className="HomeCard">
              <h5 className="HomeCardHead">Total {Contractor.name} :</h5>
              <p className="HomeCardPara">
                <span
                  style={{
                    color: item[0],
                    fontSize: "30px",
                    paddingLeft: "6px",
                  }}
                >
                  {Contractor?.length}
                </span>
              </p>
              <hr />

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <button
                  className="HomeCardViewbtn"
                  style={{
                    background: item[0],
                  }}
                  onClick={() => navigate("/user-contractor")}
                >
                  View
                </button>
              </div>
              {/* <Link to={item.link}  className="homecardLink">
                    View More
                  </Link> */}
              <div className="cardIconDiv" style={{ backgroundColor: item[0] }}>
                <i class="fa-regular fa-user"></i>
              </div>
            </div>
          </div>
          {/* ....Homeowner.... */}
          <div className="col-md-4 col-12">
            <div className="HomeCard">
              <h5 className="HomeCardHead">Total {Homeowner.name} :</h5>
              <p className="HomeCardPara">
                <span
                  style={{
                    color: item[1],
                    fontSize: "30px",
                    paddingLeft: "6px",
                  }}
                >
                  {Homeowner.length}
                </span>{" "}
              </p>
              <hr />

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <button
                  className="HomeCardViewbtn"
                  style={{
                    background: item[1],
                  }}
                  onClick={() => navigate("/user-homeowner")}
                >
                  View
                </button>
              </div>
              {/* <Link to={item.link}  className="homecardLink">
                    View More
                  </Link> */}
              <div className="cardIconDiv" style={{ backgroundColor: item[1] }}>
                <i class="fa-solid fa-user"></i>
              </div>
            </div>
          </div>

          {/* ....SubContractor.... */}
          <div className="col-md-4 col-12">
            <div className="HomeCard">
              <h5 className="HomeCardHead">Total Financier: </h5>
              <p className="HomeCardPara">
                <span
                  style={{
                    color: item[2],
                    fontSize: "30px",
                    paddingLeft: "6px",
                  }}
                >
                  {SubContractor.length}
                </span>
              </p>
              <hr />

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <button
                  className="HomeCardViewbtn"
                  style={{
                    background: item[2],
                  }}
                  onClick={() => navigate("/user-sub-contractor")}
                >
                  View
                </button>
              </div>
              <div className="cardIconDiv" style={{ backgroundColor: item[2] }}>
                <i class="fa-solid fa-users"></i>
              </div>
            </div>
          </div>
          
          {/* ....Financer.... */}
          <div className="col-md-4 col-12 mt-5">
            <div className="HomeCard">
              <h5 className="HomeCardHead">Total {Financer.name} : </h5>
              <p className="HomeCardPara">
                <span
                  style={{
                    color: item[3],
                    fontSize: "30px",
                    paddingLeft: "6px",
                  }}
                >
                  {Financer.length}
                </span>
              </p>
              <hr />

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <button
                  className="HomeCardViewbtn"
                  style={{
                    background: item[3],
                  }}
                  onClick={() => navigate("/employee-data")}
                >
                  View
                </button>
              </div>
              {/* <Link to={item.link}  className="homecardLink">
                    View More
                  </Link> */}
              <div className="cardIconDiv" style={{ backgroundColor: item[3] }}>
                <i class="fa-solid fa-users"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeCard;
