import React, { useEffect, useState } from 'react'
import CustomLoader from '../../loader/CustomLoader';
import { headLineStyle } from '../../Layout/HeadlineStyle';
import DataTable from 'react-data-table-component';
import HttpClientXml from '../../utils/HttpClientXml';
// import { Toast } from 'bootstrap';
import toast from 'react-hot-toast';
import moment from 'moment';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { MdErrorOutline } from "react-icons/md";

const customStyles = {
    rows: {
        style: {
            minHeight: '48px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#cee0eb', // set the background color for head cells
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};
const WithDrawal = () => {
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [AproveAction, setAproveAction] = useState(true);
    console.log(AproveAction, "AproveAction")

    const columns = [
        {
            name: 'SL',
            selector: row => row.sl,
            width: "4rem"
        },
        {
            name: 'Withdrawal Details Name',
            selector: row => row.details,
        },
        {
            name: 'Payment ID',
            selector: row => row.paymentId,
        },
        {
            name: 'Amount',
            selector: row => row.amount,
        },

        {
            name: 'Date of Creation',
            selector: row => row.createdAt,
        },
        {
            name: ' Status',
            selector: row => row.approvedStatus,
        },
        {
            name: 'Action',
            selector: row => row.Action,
        },

        // {
        //     name: 'Action',
        //     selector: row => row.action,
        // }
    ];
    // fetch Category DAta
    const getCategoryData = async () => {
        setIsLoading(true);
        const res = await HttpClientXml.requestData('get-all-pending-request-withdrawal', "GET", {});
        console.log("resGetCat", res)
        let apiData = []
        if (res && res?.status) {
            setIsLoading(false);
            apiData = res?.data?.map((item, i) => ({

                sl: i + 1,
                details: item?.details,
                paymentId: item?.paymentId,
                amount: item?.amount,
                createdAt: moment(item?.createdAt).format("DD-MM-YYYY"),
                approvedStatus: item?.approvedStatus,
                Action: item?.approvedStatus === "pending" ? <div><button onClick={() => approve(item?._id)} className='btn btn-success '>Yes</button>
                    &nbsp;<button
                        onClick={() => disapprove(item?._id)}
                        className='btn btn-danger '>No</button></div> :
                    item?.approvedStatus === "approved" ? <FaCheck style={{ color: 'green', marginRight: '5px', width: "35px", fontSize: "25px" }} /> :
                        item?.approvedStatus === "rejected" ? <FaTimes style={{ color: 'red', marginRight: '5px', width: "35px", fontSize: "25px" }} /> :
                            <MdErrorOutline style={{ color: 'blue', marginRight: '225px', width: "35px", fontSize: "25px" }} />
           }));
        } else {
            setIsLoading(false);
            toast.error("hh")
        }
        setTableData(apiData.reverse());
    }

    const approve = async (id) => {
        setIsLoading(true);
        const data = {
            "approvedStatus": "approved"
        }
        const res = await HttpClientXml.requestData('change-approve-status-withdraw/' + id, "PUT", data);
        if (res && res?.status) {
            setIsLoading(false);
            toast.success("aproved")
            getCategoryData();
        }
        else {
            setIsLoading(false);
            toast.error("Cancel")
        }
    }
    const disapprove = async (id) => {
        setIsLoading(true);
        const data = {
            "approvedStatus": "rejected"
        }
        const res = await HttpClientXml.requestData('change-approve-status-withdraw/' + id, "PUT", data);
        if (res && res?.status) {
            setIsLoading(false);
            toast.success("aproved")
            getCategoryData();
        }
        else {
            setIsLoading(false);
            toast.error("Cancel")
        }
    }
    useEffect(() => {
        getCategoryData();
    }, [])
    return (
        <div>

            <CustomLoader loading={isLoading} />


            <div className="d-flex justify-content-end">

                <div className="form-header">
                    <section className="piechartsBox_area">
                        <div
                            style={headLineStyle}
                            className="page-headline"
                        >
                            View Withdrawal Requests
                        </div>
                        <div>
                            <DataTable
                                columns={columns}
                                data={tableData}
                                pagination
                                striped
                                customStyles={customStyles}
                            />
                        </div>
                    </section>
                </div>
            </div >




        </div >
    )
}

export default WithDrawal