// DetailsModal.js
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Set the root element for accessibility

const customStyles = {
    rows: {
        style: {
            minHeight: '48px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#cee0eb', // set the background color for head cells
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

const DetailsModal = ({ isOpen, closeModal, details }) => {

    const [tableData, setTableData] = useState([]);
    console.log("details", details);

    const columns = [
        {
            name: 'SL',
            selector: row => row.sl,
            width: "5rem"
        },
        {
            name: 'Payment ID',
            selector: row => row.paymentId,
        },
        {
            name: 'Amout',
            selector: row => row.amount,
            // width: "15rem"
        },
        {
            name: 'Purpose',
            selector: row => row.paymentPurpose,
        },
        {
            name: 'Payment Date',
            selector: row => row.createdAt,
        }


    ];
    // fetch Category DAta
    const getCategoryData = async () => {
        // setIsLoading(true);

        let apiData = []

        apiData = details?.map((item, i) => ({

            sl: i + 1,
            id: item?._id,
            paymentId: item?.paymentId,
            amount: item?.historyType === "debit" ? <p className=' text-danger '>{item?.amount}</p>
                : <p className=' text-success  '>{item?.amount}</p>,
            paymentPurpose: item?.paymentPurpose,
            createdAt: moment(item?.createdAt).format("DD-MM-YYYY"),


        }));

        setTableData(apiData);
    }
    useEffect(() => {
        getCategoryData();
    }, [details]);
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="Details Modal"
            className=""
        >
            <a href='#' className='mtclose' onClick={closeModal}><i class="fa-solid fa-xmark"></i></a>
            <div className='inner_modal'>
                <h2 className='d-flex justify-content-center '>Details</h2>
                <div className='d-flex'>
                    <div className='d-flex'>
                        <div className='mt-2' style={{ width: '12px', height: '12px', backgroundColor: 'red' }}></div>
                        <span className='mt-1' style={{ fontSize: "13px" }}>&nbsp;:Debit</span>
                    </div>
                    <div className='d-flex mx-2'>
                        <div className='mt-2' style={{ width: '12px', height: '12px', backgroundColor: 'green' }}></div>
                        <span className='mt-1' style={{ fontSize: "13px" }}>&nbsp;:Credit</span>
                    </div>
                </div>
                {/* Render details using the 'details' prop */}
                <div className=''>
                    <DataTable
                        columns={columns}
                        data={tableData}
                        pagination
                        striped
                        customStyles={customStyles}
                    />
                </div>
                {/* Add more details rendering as needed */}
            </div>


        </Modal>
    );
};

export default DetailsModal;
