// Hooks
import React, { useState } from "react";
import "./Authentication.css";
import loginbg from "../Images/LoginBG.jpeg";
import toast from "react-hot-toast";
import { reactLocalStorage } from "reactjs-localstorage";
import { useNavigate } from "react-router-dom";
import AuthServices from "../Services/AuthServices";
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import HttpClientXml from "../utils/HttpClientXml";

export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [passord, setPassword] = useState("");
  const [show, setShow] = useState(false);

  const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  // const passWord = passord.length >= 4 && passord.length <= 15;
  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = email.trim() !== "" && passord.trim() !== "";
    if (isValid) {
      // toast.success("login successfully")
      if (!email.match(mailFormat)) {
        toast.error("Invalid Email");
        return;
      }
      // console.log(first)
    } else {
      toast.error("Please fill all the Inputs..");
      return;
    }

    const data = {
      email: email,
      password: passord,
    };
    const res = await HttpClientXml.requestData("login", "POST", data);
    console.log("resCat", res);
    if (res && res.status) {
      reactLocalStorage.setObject("userDataBuildBeta", {
        token: res?.data?.token,
      });
      toast.success("Login Successfully");
      setEmail("");
      setPassword("")
      navigate("/");
      
    } else {
      toast.error(res.message);
    }
  };

  const togglePasswordVisibility = () => {
    setShow(!show);
  };

  return (
    <>
      <section
        className="LoginPage"
        style={{ backgroundImage: `url(${loginbg})` }}
      >
        <div className="LoginBgOverlay" />
        <div className="LoginForm">
          <div className="LoginTop">
            <h5 className="LoginHead">Sign in</h5>
          </div>
          <div className="LoginBtm">
            <form action="">
              <div className="form-group">
                <input
                  name="email"
                  id="exampleEmail"
                  placeholder="Email here..."
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="form-group pass_input">
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  value={passord}
                  name="password"
                  id="examplePassword"
                  placeholder="Password here..."
                  type={show ? "text" : "password"}
                  className="form-control"
                />
                <div className="eye_icon" onClick={togglePasswordVisibility}>
                  {show ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
              <div className="form-group">
                <input type="Checkbox" />
                <span className="LoginRem">Remember Me</span>
              </div>
            </form>
            <button
              className="LoginBtn"
              type="submit"
              onClick={(e) => handleSubmit(e)}
            >
              SIGN IN
            </button>
          </div>
        </div>
      </section>
    </>
  );
}
