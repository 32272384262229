import React, { useEffect, useState } from 'react'
import CustomLoader from '../../loader/CustomLoader';
import { headLineStyle } from '../../Layout/HeadlineStyle';
import DataTable from 'react-data-table-component';
import HttpClientXml from '../../utils/HttpClientXml';
import { Toast } from 'bootstrap';
import toast from 'react-hot-toast';
import moment from 'moment';
import { ImCross } from 'react-icons/im';
import EditDeleteIcon from '../../CustomComponents/EditDeleteIcon';
import { DeleteConfirmModal } from '../../loader/DeleteConfirmModal';


const AddTask = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [categoryData, setCategoryData] = useState([])
    const [tableData, setTableData] = useState([])
    const [allTaskData, setAllaTaskData] = useState([])
    const [catId, setCatId] = useState("")
    const [isEdit, setIsEdit] = useState(false)
    const [singleTaskData, getSingleTaskData] = useState({})

    const initValue = {
        "categoryId": "",
        "name": ""
    }
    const [formValue, setFormValue] = useState(initValue)

    const columns = [
        {
            name: 'SL',
            selector: row => row.sl,
            width: "4rem"
        },
        {
            name: 'Task Name',
            selector: row => row.taskName,
        },
        {
            name: 'Category',
            selector: row => row.category,
        },
        {
            name: 'Action',
            selector: row => row.action,
        }
    ];


    //add category field
    const handleChange = (e) => {
        const { name, value } = e.target
        setFormValue(prev => ({ ...prev, [name]: value }))
    }

    // validate
    const validate = () => {
        if (!formValue?.categoryId) {
            toast.error("Category is Required!")
            return true
        }
        if (!formValue?.name) {
            toast.error("Task Name is Required!")
            return true
        }

        return false
    }

    //Submit category
    const handleSubmit = async (e) => {
        // console.log("valuesdd");
        e.preventDefault();

        if (validate()) {
            return
        }

        setIsLoading(true);

        // edit function
        if (isEdit) {
            const res = await HttpClientXml.requestData("taskName/" + singleTaskData?._id, "PUT", formValue);
            // console.log("resCat", res)
            setIsLoading(false);
            if (res && res?.status) {
                toast.success("Updated Successfully!");
                setIsEdit(false)

                setFormValue(initValue)
                getTaskData()
            } else {
                toast.error(res?.message || "Swmething Wrong");
            }
        }

        // add function
        else {
            const res = await HttpClientXml.requestData("taskName", "POST", formValue);
            // console.log("resCat", res)
            setIsLoading(false);
            if (res && res?.status) {
                toast.success("Added Successfully!");
                setFormValue(prev => ({ ...prev, name: "" }))
                getTaskData()
            } else {
                toast.error(res?.message || "Swmething Wrong");
            }
        }
    };


    // fetch Category DAta
    const getCategoryData = async () => {
        setIsLoading(true);
        const res = await HttpClientXml.requestData('get-all-service-category', "GET", {});
        console.log("resGetCat", res)
        setIsLoading(false);
        if (res && res?.status) {
            // apiData = res?.data?.map((item, i) => ({
            //     sl: i + 1,
            //     Category: item?.categoryName,
            //     createdAt: moment(item?.createdAt).format("DD-MM-YYYY"),

            //     action: <EditDeleteIcon
            //         onClickEdit={(e) => handleEdit(item)}
            //         onClickDelete={(e) => handleDelete(item?._id)}
            //     />
            // }));
            setCategoryData(res?.data)
        }
    }


    // fetch task data
    const getTaskData = async () => {
        setIsLoading(true);
        const res = await HttpClientXml.requestData('taskName', "GET", {});
        // console.log("resGetCattas", res)
        setIsLoading(false);
        if (res && res?.status) {
            setAllaTaskData(res?.data)
            if (catId) {
                filterUsingCateGory(res?.data, catId)
            }
        } else {

        }
    }

    // handleEdit
    const handleEdit = (item) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
        getSingleTaskData(item)
        setIsEdit(true)
        setFormValue({
            "categoryId": item?.categoryId,
            "name": item?.name
        })
    }

    // handle delete
    const handleDelete = async (id) => {
        const del = async () => {
            setIsLoading(true)
            const res = await HttpClientXml.requestData("taskName/" + id, "DELETE")
            setIsLoading(false)
            if (res && res?.status) {
                toast.success("Deleted Successfully!")
                getTaskData()
            } else {
                toast.error(res?.message || "Swmething Wrong");
            }
        }

        DeleteConfirmModal(del)
    }


    // filter using category
    const filterUsingCateGory = (data, catId) => {
        const fltData = data?.filter(ele => ele?.categoryId === catId)
        console.log("fltData", fltData)
        let apiData = fltData?.map((item, i) => ({
            sl: i + 1,
            taskName: item?.name,
            category: item?.categoryDetails?.categoryName,
            action: <EditDeleteIcon
                onClickEdit={(e) => handleEdit(item)}
                onClickDelete={(e) => handleDelete(item?._id)}
            />

        }))
        setTableData(apiData)
    }

    useEffect(() => {
        getCategoryData();
        getTaskData();
    }, [])




    return (
        <div>

            <CustomLoader loading={isLoading} />


            <div className="d-flex justify-content-end">

                <div className="form-header">
                    <section className="piechartsBox_area">
                        <div
                            style={headLineStyle}
                            className="page-headline"
                        >
                            {isEdit ? "Update Task" : "Add Task"}
                        </div>
                        <form className="mb-2">

                            {/* Calegory */}
                            <div className="col-12">
                                <label htmlFor="formGroupExampleInput" className='edit-tag'>
                                    Select Category<span className="text-danger">&nbsp;*</span>
                                </label>

                                <select
                                    className="form-control"
                                    aria-label="Default select example"
                                    name="categoryId"
                                    value={formValue.categoryId}
                                    onChange={handleChange}
                                >
                                    <option selected>Select Category</option>
                                    {
                                        categoryData?.map((item, i) =>
                                            <option value={item?._id}>{item?.categoryName}</option>
                                        )
                                    }
                                </select>
                            </div>

                            {/* Task Name */}
                            <div className="col-12 mt-3">
                                <label htmlFor="formGroupExampleInput" className='edit-tag'>
                                    Task Name<span className="text-danger">&nbsp;*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder='Task Name'
                                    onChange={handleChange}
                                    value={formValue.name}
                                    name="name"
                                />
                            </div>

                            {/* Button */}
                            <div className="col-1 d-flex " style={{ marginTop: "31px" }}>
                                <button
                                    type="submit"
                                    onClick={(e) => handleSubmit(e)}
                                    className="btn"
                                    style={{ background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", color: "#fff" }}
                                >
                                    {isEdit ? "Update" : "Add"}
                                </button>
                            </div>

                        </form>

                    </section>

                    {/* datatable */}
                    <section className="piechartsBox_area">
                        <div
                            style={headLineStyle}
                            className="page-headline"
                        >
                            View Task
                        </div>

                        <div className="col-12 mb-4">
                            <label htmlFor="formGroupExampleInput" className='edit-tag'>
                                Select Category<span className="text-danger">&nbsp;*</span>
                            </label>

                            <select
                                className="form-control"
                                aria-label="Default select example"
                                name="categoryId"
                                value={catId}
                                onChange={(e) => {
                                    filterUsingCateGory(allTaskData, e.target.value)
                                    setCatId(e.target.value)
                                }}
                            >
                                <option selected>Select Category</option>
                                {
                                    categoryData?.map((item, i) =>
                                        <option value={item?._id}>{item?.categoryName}</option>
                                    )
                                }
                            </select>
                        </div>

                        <div>
                            <DataTable
                                columns={columns}
                                data={tableData}
                                pagination
                                striped
                            // customStyles={customStyles}
                            />
                        </div>
                    </section>
                </div>

            </div >

        </div >
    )
}

export default AddTask