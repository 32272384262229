import React from "react";
import SingleProjectView from "./SingleProjectView";
// import PaymentDetailsCard from "../../Component/PaymentDetailsCard";
// import SupplierCard from "../../Component/SupplierCard";
// import LendersCard from "../../Component/LendersCard";
// import SingleProjectView from "../../Component/SingleProjectView";

function SingleProjectDetails() {
    return (
        <>
            {/* <div className="main_wrap">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-12">
              <div className="middlepartwrap">
                <SingleProjectView />
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-12 pr-0">
              <div className="rightsidebar">
                <PaymentDetailsCard
                  Payment="Payment Received"
                  text="Lorem ipsum dolor sit amet consectetur"
                  amout="£30,000"
                  btnname="View Details"
                  bgcolor="#04B600"
                  Receivedbtn={true}
                />
                <PaymentDetailsCard
                  Payment="Payment Due"
                  text="Lorem ipsum dolor sit amet consectetur"
                  amout="£25,000"
                  btnname="View Details"
                  bgcolor="#7F6DF0"
                  Duebtn={true}
                />

                <SupplierCard />

                <LendersCard />
              </div>
            </div>
          </div>
        </div>
      </div> */}
            <div className="main_wrap">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-12">
                            <div className="middlepartwrap">
                                <SingleProjectView />
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-12 pr-0">
                            <div className="rightsidebar">
                                {/* <PaymentDetailsCard
                  Payment="Payment Received"
                  text="Lorem ipsum dolor sit amet consectetur"
                  amout="£30,000"
                  btnname="View Details"
                  bgcolor="#04B600"
                  Receivedbtn={true}
                />
                <PaymentDetailsCard
                  Payment="Payment Due"
                  text="Lorem ipsum dolor sit amet consectetur"
                  amout="£25,000"
                  btnname="View Details"
                  bgcolor="#7F6DF0"
                  Duebtn={true}
                /> */}

                                {/* <SupplierCard /> */}

                                {/* <LendersCard /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SingleProjectDetails;
